// const baseURL = process.env.REACT_APP_API || "http://bd.freeli.io:4001/";
//const baseURL =  "https://apicdn02.freeli.io";
//const baseURL = "https://cacdn01.freeli.io/v2/v2/";
// const baseURL = "http://172.16.2.194:4001/v2/";
// const baseURL = "http://172.16.2.194:4001/v2/";
//const baseURL =  "https://cacdn02.freeli.io/v2/v2";
//const baseURL =  "/api";
const baseURL = "https://bdapicdn01.freeli.io/v2";
 //const baseURL =  "https://cadevapicdn02.freeli.io";
//const baseURL =  "http://182.163.122.134:6081/v2";
//const xmpp_domain = 'bddevquecdn02.freeli.io';
const xmpp_domain = 'bdquecdn01.freeli.io';
//const xmpp_domain = 'bddevquecdn02.freeli.io';
const return_data = {
  "FILE_SERVER": "https://wfss001.freeli.io/",
  "baseURL": baseURL,
  "xmpp_domain": xmpp_domain
}
export { return_data };
